/* 3D Slideshow */
* {
	margin: 0;
	padding: 0;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}

#slideshow {
	margin: 0 auto;
	height: 470px;
	width: 100%;
	box-sizing: border-box;
}

.entire-content {
	margin: auto;
	width: 100%;
	perspective: 800px;
	position: relative;
}

.content-carrousel {
	width: 100%;
	position: absolute;
	text-align: center;
	animation: flip 6s infinite;
	transform-style: preserve-3d;
}

@keyframes flip {
	0%,
	80% {
		transform: rotateY(360deg)
	}
}