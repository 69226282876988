.App {
  text-align: center;
}

.navbar-name {
  text-align: center !important;
  height: 64 !important;
  padding: 10 !important;
  font-size: 38px !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.height-card {
  height: calc(100% - 30px);
}

.rbc-btn-group>button {
  color: white !important;
}

.rbc-btn-group>.rbc-active {
  color: black !important;
}

.rbc-btn-group {
  background-color: #a6deca !important;
}

.loading-position {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-item {
  width: 350px;
  box-shadow: 3px 5px 5px #a6deca !important;
  background-color: lightgray !important;
  font-size: 16px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.custom-item:hover {
  background-color: #ECDCDC !important;
}

.accordion-custom-body-background {
  background-color: #F9F9F9 !important;
}

.button-with-border-radius {
  border-top-right-radius: 20px 20px !important;
  border-bottom-right-radius: 20px 20px !important;
}

.date-range-picker>div {
  background-color: white !important;
}

.chart-container {
  max-width: 1650px;
}

.table-custom-body-background {
  border-left: 5px solid #87A397 !important;
  background-color: #ebebeb !important;
}

.custom-box {
  padding: 24px;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
  border-radius: 8px;
  /* margin-top: 20px; */
  background-color: #FFFFFF;
}