.base58-table-header-cell-first {
    padding-right: 5px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 36.5px;
}

.base58-table-header-cell {
    padding-right: 5px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 36.5px;
    border-left: 1px solid white;
}

.base58-table-header-cell-title {
    display: inline;
    font-size: 0.875rem !important;
}

.base58-table-header-cell-sort-icon {
    float: right;
    cursor: pointer;
}

.base58-table-header-search-cell {
    background-color: white !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0px !important;
    padding-bottom: 2px !important;
}

.base58-table-cell {
    background-color: white !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    padding: 0px !important;
}

.base58-table-filter-criteria-wrapper {
    color: black;
}

.base58-table-filter-criteria-text {
    display: inline;
}

.base58-table-filter-criteria-clear-icon {
    display: inline;
    cursor: pointer;
    padding-top: 10px;
}