.login-container {
    text-align: center;
}

.login-content {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    display: inline-block;
    margin-top: 250px;
    vertical-align: middle;
    position: relative;
    padding: 25px;
}

.social-btn.google {
    margin-top: 7px;
}

@media only screen and (min-width: 1200px) {
    .login-title {
        display: inline-block;
        top: 50%;
        position: relative;
        vertical-align: middle;
        padding: 45px;
        font-weight: 300;
        margin-bottom: 30px;
        color: #6F8684;
    }

    .loading-position-login {
        /* position: fixed;
        top:45%;
        left: 50%; */
        margin: auto;
        height: 665px;
        background-repeat: no-repeat;
        background-size: cover;
        /* background-image: url('../../assets/icon/medicaNew.png') !important; */
        /* transform: translate(-50%, -50%); */
        /*  background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
    }

    .logo-login {
        margin: auto;
        width: auto;
        height: auto;
        text-align: center;
    }

    .custom-chip {
        cursor: pointer !important;
        padding: 20px !important;
        width: 350px;
        margin-bottom: 30px !important;
        background-color: rgb(241, 241, 241) !important;
    }

}

@media only screen and (max-width: 820px) {
    .login-title {
        display: inline-block;
        top: 50%;
        position: relative;
        vertical-align: middle;
        padding: 45px;
        font-weight: 300;
        margin-bottom: 30px;
        color: #6F8684;
    }

    .loading-position-login {
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        background-image: url('../../assets/icon/medicaNew.png') !important;
        background-size: 2000px !important;
        transform: translate(-50%, -50%);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 62%;
        background-position-y: 75%;
    }

    .custom-chip {
        cursor: pointer !important;
        padding: 20px !important;
        width: 350px;
        margin-bottom: 30px !important;
        background-color: rgb(241, 241, 241) !important;
    }

}

@media only screen and (max-width: 480px) {
    .loading-position-login {
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        background-image: url('../../assets/icon/medicaNew.png') !important;
        background-size: 900px !important;
        transform: translate(-50%, -50%);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 60%;
        background-position-y: 80%;
    }

    .login-title {
        display: inline;
        top: 50%;
        position: relative;
        vertical-align: middle;
        padding: 25px;
        font-weight: 300;
        margin-bottom: 30px;
        color: #6F8684;
    }

    .custom-chip {
        cursor: pointer !important;
        padding: 20px !important;
        width: 350px;
        margin-bottom: 30px !important;
        background-color: rgb(241, 241, 241) !important;
    }

}


/* 
.custom-chip {
    cursor: pointer !important;
    padding: 20px !important;
    width: 350px;
    margin-bottom: 30px !important;
    background-color: rgb(241, 241, 241) !important;
} */