.root {
    display: flex;
    background-color: #F9F9F9;
    height: 100vh;
}

.drawerPaper {
    width: 240px;
    background: #FFFFFF !important;
    overflow: hidden !important;
    border: none !important;
}

.content {
    overflow: hidden;
    flex-grow: 1;
    padding-top: 92px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    background-color: #F9F9F9;
}

.menuButton {
    margin-right: 16px;
}

.sidebar-item-selected {
    background: #EAFAF3 !important;
    border-radius: 2px !important;
    border: 0px 2px 0px 0px !important;
    width: 220px !important;
    margin-bottom: 8px !important;
    padding: 8px 24px 8px 24px !important;
    margin-left: 10px !important;
    height: 40px !important;
}

.sidebar-item-not-selected {
    background: #FFFFFF !important;
    margin-left: 10px !important;
    margin-bottom: 8px !important;
    padding: 8px 24px 8px 24px !important;
    height: 40px !important;
    width: 220px !important;
}

.sidebar-item-not-selected:hover {
    background: #e0e0e0 !important;
    margin-left: 10px !important;
    margin-bottom: 8px !important;
    padding: 8px 24px 8px 24px !important;
    height: 40px !important;
    width: 220px !important;
}

.sidebar-infirmary {
    background: #EAFAF3 !important;
    margin-bottom: 8px !important;
    padding-left: 35px !important;
    height: 48px !important;
    width: 240px !important;
    margin-top: 7px !important;
}

@media only screen and (min-width: 960px) {
    .drawer {
        width: 240px !important;
        flex-shrink: 0 !important;
    }

    .appBar {
        width: calc(100% - 240px) !important;
        margin-left: 240px !important;
        background: #FFFFFF !important;
    }

    .appBar-full {
        width: 100% !important;
        margin-left: 0px !important;
        background: #FFFFFF !important;
    }

    .menuButton {
        display: none !important;
    }
}

@media only screen and (max-width: 960px) {
    .appBar {
        width: 100% !important;
        margin-left: 240px !important;
        color: white !important;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .content {
        padding-left: 10px;
        padding-right: 10px;
    }
}