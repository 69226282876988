.title {
    flex-grow: 1;
    font-weight: bold;
    padding-left: 10px;
    color: #a6deca;
}

@media only screen and (min-width: 600px) {
    .title {
        padding-left: 240px;
    }
}