.border-top-total {
    border-top: 1px solid black;
    font-weight: bold;
    margin-bottom: 10px;
}

.total-cell {
    font-weight: bold !important;
    padding: 0px !important;
    padding-right: 10px !important;
}