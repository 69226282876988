.calendar-nav-btn-not-selected {
    color: #FFFFFF !important;
    height: 38px !important;
    max-height: 38px !important;
    font-size: 12px !important;
}

.calendar-nav-btn-selected {
    background-color: #1B7E53 !important;
    color: #FFFFFF !important;
    height: 38px !important;
    max-height: 38px !important;
    font-size: 12px !important;
}

.day_weekend {
    color: red;
    font-weight: bold;
}

.rbc-current-time-indicator {
    background: #f5778e;
    height: 2px;
}

.table-row-change-color:hover{
    background-color: lightgray;
}

.rbc-header button span {
    font-weight: bold !important;
}